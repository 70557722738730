import { FC } from 'react'
import { SvgProps } from '@/components/atoms/svg/svgProps'
const PantsMen: FC<SvgProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 125.85 154"
      width={width || 18}
      height={height || 18}
    >
      <use href="#pants-men-icon-definition" stroke={color || '#000'} />
    </svg>
  )
}
export default PantsMen
